.InputQuestion input {
  outline: none;
  border: none;
  border-radius: 4px;
  width: 100%;
  background-color: #1c1c1e;
  color: white;
  padding: 19px 20px 19px 20px;
}
.InputQuestion input::placeholder {
  color: gray;
  opacity: 1;
  font-size: 18px;
}
