.ProfileData {
  border: 1px solid rgb(157, 145, 145);
  max-width: 500px;
  min-width: 400px;
  border-radius: 10px;
  padding: 20px;
  margin-left: 10px;
}
.ProfileDataBox {
  padding: 10px;
}
.ProfileDataBox > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(157, 145, 145);
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.ProfileDataBox > div > h3 {
  margin-right: 10px;
  min-width: 100px;
}
.FetchProfile {
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  background: #b10107;
  margin-left: 10px;
  color: #f6f6f6;
  cursor: pointer;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
@media only screen and (max-width: 600px) {
  .ProfileData {
    min-width: 200px;
  }
  img {
    width: 150px;
    height: 150px;
  }
  .ProfileDataBox > div {
    flex-direction: column;
    text-align: center;
  }
}
