.ChatBox {
  max-width: 500px;
  padding: 20px;
  /* background: #172329; */
  background: #2c2c2e;
  border-radius: 13px;
  border: 1px solid #2f2f2f;
  min-width: 500px;
  height: 100%;
}
.ChatBox__messages {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
  padding: 20px;
}
.ChatHistory {
  display: flex;
  flex-direction: column;
}
.receive,
.send {
  padding: 10px;
  font-size: 24px;
}
.receive {
  align-self: flex-start;
}
.send {
  align-self: flex-end;
}

.receive > p {
  animation: moveIn 0.2625s forwards 375ms cubic-bezier(0.215, 0.61, 0.355, 1);
  background: #3a3a3c;
  padding: 9px 17px 11px 17px;
  border-radius: 24px 24px 24px 0;
  max-width: 90%;
  min-width: 120px;
}
.send > p {
  background: #e5e6ea;
  color: #303030;
  border-radius: 24px 0 24px 24px;
  padding: 9px 17px 11px 17px;
  max-width: 90%;
  min-width: 120px;
  animation: message 0.15s ease-out 0s forwards;
  animation-delay: var(--delay);
}

/* Current question */

.CurrentQuestion {
}
.CurrentQuestion p {
  margin-bottom: 20px;
}
.buttons {
  text-align: center;
}
.SignOut {
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  background: #b10107;
  color: #f6f6f6;
  cursor: pointer;
  margin-bottom: 20px;
  text-align: center;
}
.Congrats {
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  background: #b10107;
  margin-left: 10px;
  color: #f6f6f6;
  cursor: pointer;
  margin-bottom: 20px;
}
@media only screen and (max-width: 600px) {
  .ChatBox {
    min-width: 200px;
  }
}
.ProgressBar {
  margin: 20px 0;
}
.ProgressBar > h4,
.ProgressBar > h2 {
  margin-bottom: 10px;
  color: #f0be35;
}
