.PhotoQuestion {
}
.SingleRadio {
  display: flex;
}
.SingleRadio input {
  margin-right: 10px;
  align-self: center;
}
.SingleRadio p {
  margin: 0;
}
.PhotoQuestion input[type="text"] {
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
}
.WebcamBox {
  text-align: center;
  margin-top: 10px;
}
.WebcamBox > div {
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
  background: #b10107;
  padding: 10px 12px;
  display: flex;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 194px;
  cursor: pointer;
}
.WebcamBox button {
  outline: none;
  border: none;
  font-size: 18px;
  background: #b10107;
  margin-left: 10px;
  color: #f6f6f6;
  cursor: pointer;
}
.WebcamBox img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.StartButton {
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  background: #b10107;
  color: #f6f6f6;
  cursor: pointer;
}
.PhotoQuestion > div:first-child {
  text-align: center;
  margin-bottom: 20px;
}
