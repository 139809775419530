.RadioQuestion {
}
.SingleRadio {
  display: flex;
}
.SingleRadio input {
  margin-right: 10px;
  align-self: center;
}
.SingleRadio p {
  margin: 0;
}
.RadioQuestion input[type="text"] {
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
}
