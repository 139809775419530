.DateQuestion {
  display: block;
}

.DateButton {
  padding: 10px;
  background: #3a3a3c;
  padding: 9px 17px 11px 17px;
  border-radius: 24px 24px 24px 0;
  max-width: 90%;
  min-width: 120px;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}
