.App {
  /* background-color: #111b20; */
  background-color: #1c1c1e;
  /* padding: 20px; */
  color: white;
  min-height: 100vh;
  width: calc(100vw-20px);
  padding: 20px;
}
.Container {
  display: flex;
  justify-content: space-around;
  padding-top: 40px;
}
@media only screen and (max-width: 600px) {
  .Container {
    flex-direction: column;
  }
  .Container > div {
    margin-bottom: 20px;
  }
}
